@font-face {
    font-family: RobotoCondensed;
    src: url(assets/roboto-condensed.light.ttf);
    font-weight: 800;
}

@font-face {
    font-family: EconomicaBold;
    src: url(assets/economica-bold.ttf);
}

body {
    text-align: center;
    font-size: 20px;
    padding: 2.5%;
    font-family: 'RobotoCondensed';
}

h1, h2, h3, h4, h5 {
    font-family: 'EconomicaBold';
}

.profile {
    width: 200px;
    height: 200px;
    border-radius: 100px;
}